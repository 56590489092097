<template>
  <div role="form">
    <div class="ibox">
      <div class="ibox-content">
        <div class="row p-t-md">
          <div class="col-xs-12 col-md-4">
            <h1 class="form-section-title">Domain Registration</h1>
            <p class="">Register a domain for your team website, this is included with your WrestlingIQ Website subscription.</p>
            <p class="">Already have a domain? Email <a href="mailto:support@wrestlingiq.com">support@wrestlingiq.com</a> to get it added to your WrestlingIQ account.</p>
          </div>
          <div class="col-xs-12 col-md-4 col-md-offset-2">
            <div class="form-group">
              <label>Search Available Domain Names</label>
              <p class="text-gray-600">
                We recommend using a .com or .org domain if possible.
              </p>
              <input type="text" 
                      class="form-control" 
                      v-model="domainName"
                      placeholder="mywrestlingteam.com"
                      :disabled="checking || canRegister"
                      />
              <div v-if="checking" class="m-t-sm">
                <i class="fa fa-spinner fa-spin"></i> Checking availability of {{ checkedDomain }}...
              </div>

              <div v-if="!checking && dnsimpleResponse" class="m-t-sm">
                <div v-if="canRegister" class="text-primary font-heavy">
                  <i class="fa fa-check"></i> {{ checkedDomain }} is available!
                </div>
                <div v-else class="text-danger font-heavy">
                  <i class="fa fa-times"></i> {{ checkedDomain }} is not available
                </div>
              </div>
            </div>
            <div class="form-group" v-if="!canRegister">
              <ladda-button @lbClicked="checkDomain" el-class="btn-primary btn-block" :loading="checking">
                Check Availability
              </ladda-button>
            </div>

            <div class="form-group m-t-lg text-center" v-if="canRegister">
              <ladda-button @lbClicked="registerDomain" el-class="btn-primary btn-block" :loading="registering">
                Register Domain
              </ladda-button>
              <button @click="resetForm" class="btn btn-default btn-block m-t-sm">
                Search for Different Domain
              </button>
              <small class="text-gray-600 m-t-xs">
                Your website subscription comes with one free domain registration, so be sure this is the domain you want to use.
                This may take a minute or two to complete.
              </small>
            </div>

            <p class="text-danger font-heavy" v-if="error">
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';
import { errorableMix } from './mixins/errorable_mix';
export default {
  name: 'domain-register',
  components: { LaddaButton },
  mixins: [errorableMix],
  data() {
    return {
      domainName: '',
      checkedDomain: '',
      checking: false,
      dnsimpleResponse: null,
      registering: false
    };
  },

  computed: {
    canRegister() {
      return this.dnsimpleResponse &&
             this.dnsimpleResponse.data &&
             this.dnsimpleResponse.data.available &&
             !this.dnsimpleResponse.data.premium &&
             this.dnsimpleResponse.data.domain === this.checkedDomain;
    }
  },

  methods: {
    checkDomain: _.debounce(function() {
      if (!this.domainName) {
        this.dnsimpleResponse = null;
        return;
      }

      this.checking = true;
      this.error = false;
      this.checkedDomain = this.domainName.replace(/^(https?:\/\/)?(www\.)?/, '');

      axios.post('/api/v1/website_domains/check', {
        website_domain: {
          domain_name: this.checkedDomain
        }
      })
      .then(response => {
        this.dnsimpleResponse = response.data;
        console.log(this.dnsimpleResponse);
      })
      .catch(error => {
        this.error = true;
        this.errorMessage = this.parseErrorResponse(error);
        console.error(error);
      })
      .finally(() => {
        this.checking = false;
      });
    }, 500),

    registerDomain() {
      if (!this.canRegister) return;

      this.registering = true;
      this.error = false;

      axios.post('/api/v1/website_domains', {
        website_domain: {
          domain_name: this.checkedDomain
        }
      })
      .then(() => {
        window.location.assign('/add_ons/website');
      })
      .catch(error => {
        this.error = true;
        this.errorMessage = this.parseErrorResponse(error);
        console.error(error);
      })
      .finally(() => {
        this.registering = false;
      });
    },

    resetForm() {
      this.domainName = '';
      this.checkedDomain = '';
      this.dnsimpleResponse = null;
      this.error = false;
      this.errorMessage = '';
    }
  }
};
</script>
