<template>
    <div>
        <div class="text-left" v-if="loading || pairingInProgress">
            <p>
                <span v-if="pairingInProgress">Next round is being generated...</span>
                <spinner :inline="true"></spinner>
            </p>
        </div>
        <div>
            <div>
                <div class="row" v-if="showPrint">
                  <div class="col-xs-12">
                    <button v-if="!showGenerateUI" class="btn btn-primary btn-lg btn-block text-heavy" @click="showGenerateUI = true">Generate Next Round</button>
                    <button v-else class="btn btn-default btn-lg btn-block text-heavy" @click="showGenerateUI = false">Back to Bouts</button>
                  </div>
                </div>
                <div class="row m-t-md border-t border-gray-200 border-solid p-t-lg" v-if="!showGenerateUI && selectedRound !== null">
                    <div class="col-xs-12 col-sm-6">
                        <h1 class="text-semi m-t-none">
                            Viewing Round {{ selectedRound }}
                        </h1>
                        <div class="round-switcher" v-if="generatedRounds.length > 0">
                            <label>Change round to...</label>
                            <select v-model.number="selectedRound" class="form-control">
                                <option v-for="round in generatedRounds" v-bind:value="round">
                                    {{ parseInt(round) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 text-right">
                        <a v-if="showPrint" :href="'/events/' + eventId + '/print'" target="_blank"
                           class="btn btn-success">Print Bouts</a>
                    </div>
                </div>
            </div>
            <div v-if="showGenerateUI">
                <div v-if="pendingFinalRosters">
                    <div class="alert alert-danger m-t-m">
                        <h3 class="text-semi m-t-none">
                            Not All Teams Checked In
                        </h3>
                        <p>
                            The following teams have not finalized their rosters for round {{ generatedRounds.length + 1 }}:
                        </p>
                        <ul>
                            <li v-for="ts in teamsMissingRosters">
                                {{ ts.team.name }}
                            </li>
                        </ul>
                        <p class="m-t-xs">
                            Once all teams have finalized their rosters, pairings can be generated for the next round.
                        </p>
                    </div>
                    <small class="m-t-sm" v-if="teamScoreLoading">
                        Checking for updates... <spinner :inline="true"></spinner>
                    </small>
                </div>
                <div v-if="readyToPair">
                    <template v-if="isHost">
                        <h3 class="text-semi m-t-m">Generate pairings for round {{ generatedRounds.length + 1 }}</h3>
                        <div role="form" class="m-t-md">
                            <div class="form-group">
                                <label>Allowable age difference (+/- in years)</label>
                                <input v-model.number="ageDiff" type="number" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Allowable weight difference (+/- in lbs)</label>
                                <input v-model.number="weightDiff" type="number" class="form-control">
                            </div>
                        </div>
                        <ladda-button @lbClicked="generatePairings"
                                      el-class="btn-primary btn-lg btn-block text-heavy m-t-lg"
                                      :loading="creatingPairings">
                            Generate Pairings
                        </ladda-button>
                    </template>
                    <template v-else>
                        <h3 class="text-semi m-t-m">Please wait for host to generate pairings</h3>
                        <p>
                            Once the host team has generated pairings, this page will show you pairings for each round.
                        </p>
                    </template>
                </div>
            </div>
            <div v-if="!showGenerateUI">
                <div v-for="(match, index) in matchesForRound()" class="print-block">
                  <div class="m-t-md bout">
                    <div class="row">
                      <div class="col-xs-6 col-sm-3">
                        <div class="number">
                          Bout R{{ selectedRound }}-{{ boutId(match) }}
                          <span v-if="isHost && showPrint">
                                            &nbsp; &nbsp;
                                            <a @click.prevent="showDeleteModal(match)" class="text-danger">
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </span>
                        </div>
                      </div>
                      <div class="col-xs-6 col-sm-3 col-sm-push-6">
                        <div class="match-edit flex flex-col gap-xxs">
                          <div v-if="!match.editing_mat_assignment" :class="{ 'text-danger': !match.mat_assignment }">
                            Mat #{{ match.mat_assignment || ' Unassigned' }} <i class="fa fa-edit" @click.prevent="match.editing_mat_assignment = true"></i>
                          </div>
                          <div v-if="match.editing_mat_assignment" class="text-left p-xs flex flex-col gap-xxs">
                            <label>Mat #</label>
                            <input type="text" v-model="match.mat_assignment" class="form-control" />
                            <a class="btn btn-primary w-full" @click.prevent="updateMatAssignment(match, match.mat_assignment)">Save</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex align-items-center justify-space-evenly">
                      <div class="">
                        <div v-html="wrestlerInfoHtml(match.wrestler)"></div>
                      </div>
                      <div class="">
                        <p><strong>vs.</strong></p>
                      </div>
                      <div class="">
                        <div v-html="wrestlerInfoHtml(match.opponent)"></div>
                      </div>
                    </div>
                    <div class="flex align-items-center justify-center m-b-sm">
                      <div v-if="match.outcome_slug" class="text-success flex gap-x-m">
                        <span><i class="fa fa-check"></i>Match Complete</span>
                        <a @click.prevent="updateMatchCompleted(match)" class="text-danger"><i class="fa fa-undo"></i></a>
                      </div>
                      <div v-else>
                        <button v-if="isHost" @click="updateMatchCompleted(match)" class="btn btn-default btn-compact">
                          Mark Match Complete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <h3 class="text-semi m-t-lg m-b-lg">
                    Wrestlers without opponents
                </h3>
                <div v-for="match in byesForRound()" class="m-b-md">
                    <div v-html="wrestlerInfoHtml(match.wrestler)"></div>
                    <div>
                        <template v-if="homeWpForManualPair === match.wrestler">
                            <div class="alert alert-info">
                                Currently selected
                            </div>
                        </template>
                        <template v-else-if="manualPairing">
                            <a @click.prevent="finishManualPair(match.wrestler)" class="btn btn-default">
                                Pair with {{ getFullName(match.wrestler) }}...
                            </a>
                        </template>
                        <template v-else-if="isHost">
                            <a @click.prevent="manualPairingStarted(match.wrestler)" class="btn btn-default">Create pairing...</a>
                        </template>
                    </div>
                </div>
                <div v-if="manualPairing" id="wp-for-pairing">
                    <p>
                        Select an opponent for...
                    </p>
                    <div v-html="wrestlerInfoHtml(homeWpForManualPair)"></div>
                    <a class="btn btn-danger" @click.prevent="cancelManualPair">Cancel</a>
                </div>
            </div>
        </div>
        <div v-if="error">
            <p class="text-danger">{{ errorMessage }}</p>
        </div>
        <div id="manual-pair-modal" class="modal fade" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Create Bout</h4>
                    </div>
                    <div class="modal-body">
                        <bout-create v-if="confirmBoutCreate"
                                      :wp="homeWpForManualPair"
                                      :opponent="awayWpForManualPair"
                                      :round="selectedRound"
                                      :event_id="eventId"
                        ></bout-create>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
        <div id="delete-match-modal" class="modal fade" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Delete Bout</h4>
                    </div>
                    <div class="modal-body">
                        <bout-destroy v-if="confirmBoutDestroy"
                                      :match="matchForDestroy"
                        ></bout-destroy>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
import BoutCreate from './bout_create.vue';
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import BoutDestroy from './bout_destroy.vue';

export default {
  name: 'pairings',
  components: {
    BoutDestroy, BoutCreate, LaddaButton, Spinner,
  },
  props: {
    eventId: {
      type: Number,
      required: true
    },
    showPrint: {
      type: Boolean,
      default: false
    },
    isHost: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Creating is for hitting the POST endpoint
      creatingPairings: false,
      // pairingInProgress is for polling the event to pull down pairing status and matches after generate is pressed
      pairingInProgress: false,
      // Used for polling the event to pull down pairing status and matches
      eventInterval: null,
      // Used for polling whether rosters are finalized
      rosterInterval: null,

      teamScoreLoading: false,
      loading: false,
      error: false,
      errorMessage: 'Error getting event details',
      event: null,

      teamScores: null,

      selectedRound: null,
      matchesByRound: {},

      ageDiff: 2,
      weightDiff: 7,

      manualPairing: false,
      homeWpForManualPair: null,
      awayWpForManualPair: null,
      confirmBoutCreate: false,

      confirmBoutDestroy: false,
      matchForDestroy: null,

      showGenerateUI: false,
    };
  },
  computed: {
    generatedRounds() {
      return _.sortBy(_.keys(this.matchesByRound));
    },
    readyToPair() {
      const vm = this;
      if (!vm.event) {
        return false;
      }

      return vm.event.pairing_status === 'ready_to_pair';
    },
    pendingFinalRosters() {
      const vm = this;
      if (!vm.event) {
        return false;
      }

      return vm.event.pairing_status === 'ineligible';
    },
    teamsMissingRosters() {
      const vm = this;
      if (!vm.teamScores) {
        return [];
      }

      return _.filter(vm.teamScores, (ts) => ts.roster_status !== 'final');
    },
  },
  watch: {
    pairingInProgress(newVal, oldVal) {
      console.log('pairingInProgress', newVal, oldVal);
      if (oldVal === true && newVal === false) {
        this.$notificationManager.$emit('pairing-complete');
      }
    }
  },
  created() {
    const vm = this;
    vm.getEvent();
    vm.$notificationManager.$on('roster-finalized', vm.getEvent);
    vm.$notificationManager.$on('bout-create-cancel', vm.cancelBoutCreate);
    vm.$notificationManager.$on('bout-created', vm.boutCreated);

    vm.$notificationManager.$on('bout-delete-cancel', vm.hideDeleteModal);
    vm.$notificationManager.$on('bout-deleted', vm.handleBoutDeleted);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('roster-finalized', vm.getEvent);
    vm.$notificationManager.$off('bout-create-cancel', vm.cancelBoutCreate);
    vm.$notificationManager.$off('bout-created', vm.boutCreated);

    vm.$notificationManager.$off('bout-delete-cancel', vm.hideDeleteModal);
    vm.$notificationManager.$off('bout-deleted', vm.handleBoutDeleted);

    vm.clearEventPoll();
    if (vm.rosterInterval) {
      clearInterval(vm.rosterInterval);
    }
  },
  methods: {
    boutId(match) {
      const homeAbbr = _.upperCase(_.get(match.wrestler, 'last_name', '').substr(0, 2));
      const oppAbbr = _.upperCase(_.get(match.opponent, 'last_name', '').substr(0, 2));
      return `${homeAbbr}-${oppAbbr}`;
    },
    updateMatAssignment(match, matAssignment) {
      const vm = this;
      const url = vm.$apiService.matchUrl(match.id);
      const params = { match: { mat_assignment: matAssignment } };
      axios.put(url, params)
        .then((response) => {
          match.editing_mat_assignment = false;
        })
        .catch((error) => {
          vm.$notificationManager.$emit('show-toast', 'Failed to update mat assignment', false);
        });
    },
    updateMatchCompleted(match) {
      const vm = this;
      let params;
      if (match.outcome_slug) {
        params = { match: { outcome_slug: null, outcome_name: null } };
      } else {
        params = { match: { outcome_slug: 'ns', outcome_name: 'not_scored' } };
      }

      const url = vm.$apiService.matchUrl(match.id);
      axios.put(url, params)
        .then((response) => {
          match.outcome_slug = params.match.outcome_slug;
          match.outcome_name = params.match.outcome_name;
        })
        .catch((error) => {
          vm.$notificationManager.$emit('show-toast', 'Failed to update match status', false);
        });
    },
    getFullName(wp) {
      return _.get(wp, 'full_name', '');
    },
    manualPairingStarted(wp) {
      const vm = this;
      vm.homeWpForManualPair = wp;
      vm.manualPairing = true;
    },
    finishManualPair(wp) {
      this.awayWpForManualPair = wp;
      this.confirmBoutCreate = true;
      $('#manual-pair-modal').modal('show');
    },
    cancelManualPair() {
      const vm = this;
      // todo cancel modal
      vm.homeWpForManualPair = null;
      vm.awayWpForManualPair = null;
      vm.manualPairing = false;
      vm.hideModal();
    },
    cancelBoutCreate() {
      const vm = this;
      vm.hideModal();
      vm.awayWpForManualPair = null;
    },
    hideModal() {
      const vm = this;
      vm.confirmBoutCreate = false;
      $('#manual-pair-modal').modal('hide');
    },
    boutCreated() {
      const vm = this;
      vm.cancelManualPair();
      vm.getEvent();
    },
    showDeleteModal(match) {
      const vm = this;
      vm.matchForDestroy = match;
      vm.confirmBoutDestroy = true;
      $('#delete-match-modal').modal('show');
    },
    hideDeleteModal() {
      const vm = this;
      vm.matchForDestroy = null;
      vm.confirmBoutDestroy = false;
      $('#delete-match-modal').modal('hide');
    },
    handleBoutDeleted() {
      const vm = this;
      vm.hideDeleteModal();
      vm.getEvent();
    },
    wrestlerInfoHtml(wp) {
      if (wp) {
        return `<h3 class="text-semi">${wp.full_name}</h3><p>${wp.age} years old<br/>${wp.weight_class} lbs<br/>${wp.experience}<br/>${wp.team.name}</p>`;
      }

      return '';
    },
    allMatchesInRound() {
      const index = this.selectedRound;
      return this.matchesByRound[index];
    },
    matchesForRound() {
      const vm = this;
      return vm.rejectByes(vm.allMatchesInRound());
    },
    byesForRound() {
      const vm = this;
      return vm.rejectPairedMatches(vm.allMatchesInRound());
    },
    rejectByes(matches) {
      return _.reject(matches, ['opponent', null]);
    },
    rejectPairedMatches(matches) {
      return _.filter(matches, ['opponent', null]);
    },
    generatePairings: _.throttle(function () {
      const vm = this;

      if (vm.creatingPairings) {
        return;
      }

      if (vm.ageDiff === '' || vm.weightDiff === '') {
        vm.error = true;
        vm.errorMessage = 'Please add an age and weight diff.';
        return;
      }
      vm.error = false;

      vm.creatingPairings = true;
      vm.pairingInProgress = true;
      const url = vm.$apiService.pairingCriteriaUrl();
      const params = {
        pairing_criteria: {
          age: vm.ageDiff,
          weight_difference: vm.weightDiff,
          rounds: 1,
          event_id: vm.eventId,
        },
      };

      axios.post(url, params)
        .then((response) => {
          vm.creatingPairings = false ;
          vm.showGenerateUI = false;
          vm.pollEvent();
        })
        .catch((error) => {
          const { status } = error.response;
          vm.creatingPairings = false;
          if (status === 409) {
            // Pairings are being generated currently, don't throw an error here, wait for the next poll
          } else {
            vm.errorMessage = 'Error creating pairings';
            vm.error = true;
          }
        });
      // this should POST to create a new pairing criteria, which should also enqueue a pairing job
      // Then we need to pull to refresh the event every 3 seconds until the pairings are complete
    }, 500),
    clearEventPoll() {
      const vm = this;

      if (vm.eventInterval) {
        clearInterval(vm.eventInterval);
      }
    },
    pollEvent() {
      // load the list event ever 3 seconds to see if pairings are up
      const vm = this;

      vm.clearEventPoll();

      vm.eventInterval = setInterval((() => {
        vm.getEvent();
      }), 3000);
    },
    pollForTeamScores() {
      // load the list event ever 5 seconds to see if teams have checked in
      const vm = this;

      if (vm.rosterInterval) {
        clearInterval(vm.rosterInterval);
      }

      vm.rosterInterval = setInterval((() => {
        vm.loadAllTeamScores();
      }), 5000);
    },
    loadAllTeamScores() {
      const vm = this;
      const url = vm.$apiService.eventTeamScoresUrl(vm.eventId);
      vm.teamScoreLoading = true;

      vm.$apiService.loadAllPages(url, 'team_scores')
        .then((objects) => {
          vm.teamScores = objects;
          vm.teamScoreLoading = false;
        })
        .catch((error) => {
          vm.teamScoreLoading = false;
          vm.errorMessage = `Error retrieving teams ${error.toString()}`;
          vm.error = true;
        });
    },
    loadAllMatches() {
      const vm = this;
      const url = vm.$apiService.eventMatchesUrl(vm.eventId);

      vm.$apiService.loadAllPages(url, 'matches')
        .then((objects) => {
          vm.parseMatches(objects);
          vm.selectedRound = vm.generatedRounds.length;
          if (vm.generatedRounds.length === 0) {
            vm.showGenerateUI = true;
          }
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving pairings ${error.toString()}`;
          vm.error = true;
        });
    },
    parseMatches(matches) {
      const vm = this;
      const matchesWithMatAssignmentProperty = _.map(matches, (match) => {
        match.editing_mat_assignment = false;
        return match;
      });
      const roundNumbers = _.uniq(_.map(matchesWithMatAssignmentProperty, 'round'));
      roundNumbers.forEach((rn) => {
        const roundMatches = _.filter(matchesWithMatAssignmentProperty, { round: rn });
        vm.$set(vm.matchesByRound, rn, roundMatches);
      });
    },
    getEvent() {
      const vm = this;
      const getUrl = vm.$apiService.eventUrl(vm.eventId);
      vm.loading = true;
      axios.get(getUrl)
        .then((response) => {
          vm.event = response.data;
          vm.pairingInProgress = vm.event.pairing_status === 'processing';
          // If we are pairing in progress, continue to poll.
          // Otherwise we have no use to continue polling
          if (!vm.pairingInProgress) {
            vm.clearEventPoll();
          }
          // Load the team scores to know about pairing / roster finalization. Begin polling to see if
          // it has changed
          vm.loadAllTeamScores();
          vm.pollForTeamScores();

          // Load all matches we currently have paired across all rounds
          vm.loadAllMatches();
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving event ${error.toString()}. Please try again by refreshing the page or contact support.`;
          vm.error = true;
        });
    },
  },
};
</script>
