/*
  This ended up being a mixin, instead of a component/wrapper, due to issues with JustiFi's web component.
  If you include this mixin, here are the steps to take.

  1. Make sure the props for this mix get passed into your component.
  2. Include markup, like is shown in update, in your template html.
     This example is from update_cc.vue, which uses the more modern validationproviders
     but it could be simpler for Justifi.
                   <div class="form-group">
                <label for="address_2" class="font-normal">
                  Address line 2 (optional)
                </label>
                <input autocomplete="address-line2" v-model="addressLine2" id="address_2" type="text"
                       class="form-control">
              </div>
              <div class="form-group">
                <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                  <label for="locality-input" class="font-normal">
                    City
                  </label>
                  <input autocomplete="address-level2" v-model="city" id="locality-input" type="text" ref="city"
                         :class="inputClasses(errors[0])">
                  <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 m-b-only-xs-m">
                    <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                      <label for="administrative_area_level_1-input" class="font-normal">
                        State
                      </label>
                      <the-mask
                          v-model="state"
                          mask="AA"
                          type="text"
                          :class="inputClasses(errors[0])"
                          ref="state"
                          autocomplete="address-level2"
                          id="administrative_area_level_1-input"
                      ></the-mask>
                      <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                  <div class="col-xs-12 col-sm-6 m-b-only-xs-m">
                    <ValidationProvider name="zip" rules="required" v-slot="{ errors }">
                      <label for="postal_code-input" class="font-normal">
                        Zip
                      </label>
                      <the-mask
                          v-model="zip"
                          mask="#####"
                          type="tel"
                          autocomplete="postal-code"
                          :class="inputClasses(errors[0])"
                          ref="zip"
                          id="postal_code-input"
                      ></the-mask>
                      <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                  <label for="country-input" class="font-normal">
                    Country
                  </label>
                  <select v-model="country" class="form-control">
                    <option selected disabled></option>
                    <option v-for="country in countries" :value="country.short_name" id="country-input">{{
                        country.long_name
                      }}
                    </option>
                  </select>
                  <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
    3. At some point, you will need to call setupPaymentElement with a setup/payment intent, preferably with forceNextTick. This will render the payment form.
    4. When the button is pressed to submit payment, call getPaymentToken.
    5. Make sure you have implemented a tokenized(token) method on your vue component to receive the token and fire it off.
 */
import { cardLogoMix } from './card_logo_mix.js';

export const billingPartnerMix = {
  mixins: [cardLogoMix],
  props: {
    billingPartner: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ['stripe', 'justifi'].includes(value)
      }
    },
    billingAcctId: {
      type: String,
    },
    billingKey: {
      type: String,
    },
    billingIntentSecret: {
      type: String,
    },
    teamName: {
      type: String,
    },
    teamCurrency: {
      type: String,
      default: 'usd',
    },
    billingProfile: {
      type: Object,
      required: true,
    },
    // We will pre-fill based on address questions earlier in the flow
    // assuming this is the first time someone comes through and we
    // don't already have saved billing info
    bestGuessBillingInfo: {
      type: Object,
      default() { return {}; },
    },
    useGoogleAutocomplete: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      billingPartnerId: null,
      billingPartnerKey: null,
      stripe: null,
      intentSecret: null,
      creatingToken: false,
      apiKey: null,
      justifiLoading: true,
      // JustiFi specific stuff for the card form
      nameOnCard: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      countries: [
        { long_name: "Australia", short_name: "AU" },
        { long_name: "Belgium", short_name: "BE" },
        { long_name: "Brazil", short_name: "BR" },
        { long_name: "Bulgaria", short_name: "BG" },
        { long_name: "Canada", short_name: "CA" },
        { long_name: "China", short_name: "CN" },
        { long_name: "Cyprus", short_name: "CY" },
        { long_name: "Czechia", short_name: "CZ" },
        { long_name: "Denmark", short_name: "DK" },
        { long_name: "Estonia", short_name: "EE" },
        { long_name: "Finland", short_name: "FI" },
        { long_name: "France", short_name: "FR" },
        { long_name: "Germany", short_name: "DE" },
        { long_name: "Greece", short_name: "GR" },
        { long_name: "Hong Kong SAR China", short_name: "HK" },
        { long_name: "India", short_name: "IN" },
        { long_name: "Ireland", short_name: "IE" },
        { long_name: "Italy", short_name: "IT" },
        { long_name: "Japan", short_name: "JP" },
        { long_name: "Latvia", short_name: "LV" },
        { long_name: "Lithuania", short_name: "LT" },
        { long_name: "Luxembourg", short_name: "LU" },
        { long_name: "Malaysia", short_name: "MY" },
        { long_name: "Malta", short_name: "MT" },
        { long_name: "Mexico", short_name: "MX" },
        { long_name: "Netherlands", short_name: "NL" },
        { long_name: "New Zealand", short_name: "NZ" },
        { long_name: "Norway", short_name: "NO" },
        { long_name: "Poland", short_name: "PL" },
        { long_name: "Portugal", short_name: "PT" },
        { long_name: "Romania", short_name: "RO" },
        { long_name: "Singapore", short_name: "SG" },
        { long_name: "Slovakia", short_name: "SK" },
        { long_name: "Slovenia", short_name: "SI" },
        { long_name: "Spain", short_name: "ES" },
        { long_name: "Sweden", short_name: "SE" },
        { long_name: "Switzerland", short_name: "CH" },
        { long_name: "United Kingdom", short_name: "GB" },
        { long_name: "United States", short_name: "US" }
      ],
      reusePaymentMethodInFuture: true,
      email: '',
      addressAutocomplete: null,
      idDataMap: {
        'location-input': 'addressLine1',
        'locality-input': 'city',
        'administrative_area_level_1-input': 'state',
        'postal_code-input': 'zip',
        'country-input': 'country',
      },
    }
  },
  mounted() {
    // Although axios is already setup with this, the BillingPartnerMix needs it explicitly
    // If we ever embed this elsewhere, it will need to change.
    this.apiKey = document.querySelector('meta[name=ax-team-token]')?.content;
    this.billingPartnerId = this.billingAcctId;
    this.billingPartnerKey = this.billingKey;
    this.intentSecret = this.billingIntentSecret;
    this.email = this.billingProfile?.email;
    this.fetchBillingAcctInfo();

    // Saved billing profile info, if the user saved it before
    if (this.billingProfile?.default_payment_method_reusable) {
      this.nameOnCard = this.billingProfile.name_on_card;
      this.addressLine1 = this.billingProfile.address_line_1;
      this.addressLine2 = this.billingProfile.address_line_2;
      this.city = this.billingProfile.city;
      this.state = this.billingProfile.state;
      this.zip = this.billingProfile.zip;
      this.country = this.billingProfile.country;
    } else if (this.hasGuessBillingInfo) {
      // In this situation we are preloading the billing info from an address question previously asked
      this.nameOnCard = this.bestGuessBillingInfo.name_on_card;
      this.addressLine1 = this.bestGuessBillingInfo.address_line_1;
      this.addressLine2 = this.bestGuessBillingInfo.address_line_2;
      this.city = this.bestGuessBillingInfo.city;
      this.state = this.bestGuessBillingInfo.state;
      this.zip = this.bestGuessBillingInfo.zip;
    } else {
      // If we have no best guess, we only prefill the country based on the teams currency.
      // We currencly prefill USD to US, CAD to CA and NZD to NZ
      switch (this.teamCurrency) {
        case 'cad':
          this.country = 'CA';
          break;
        case 'nzd':
          this.country = 'NZ';
          break;
        default:
          this.country = 'US';
          break;
      }
    }

    this.initGoogleAutocomplete();
  },
  beforeDestroy() {
    if (this.addressAutocomplete) {
      google.maps.event.clearInstanceListeners(this.addressAutocomplete);
      this.addressAutocomplete = null;
    }
  },
  computed: {
    hasGuessBillingInfo() {
      return Object.keys(this.bestGuessBillingInfo).length !== 0
    },
    hasSavedCard() {
      return this.billingProfile.default_payment_method_id && this.billingProfile.default_payment_method_reusable;
    },
    useStripe() {
      return this.billingPartner === 'stripe';
    },
    useJustifi() {
      return this.billingPartner === 'justifi';
    },
    cardLogo() {
      switch (this.billingProfile.default_payment_method_brand) {
        case 'visa':
          return this.visaLogo;
        case 'mastercard':
          return this.mastercardLogo;
        case 'amex':
        case 'american_express':
          return this.amexLogo;
        case 'discover':
          return this.discoverLogo;
        default:
          return `<div>${this.billingProfile.default_payment_method_brand}</div>`;
      }
    },
    isUSA() {
      return this.country === 'US';
    },
  },
  methods: {
    initGoogleAutocomplete() {
      if (!this.useGoogleAutocomplete) {
        return;
      }

      // Setup Google Autocomplete
      let vm = this;
      try {
        const input = document.getElementById('location-input');
        // Skip autocomplete if we don't have this element in the DOM
        if (!input) {
          return;
        }
        // Create the autocomplete object
        this.addressAutocomplete = new google.maps.places.Autocomplete(input, {
          fields: ['address_components', 'geometry', 'name'],
          types: ['address'],
        });

        // Add a listener for when a place is selected
        this.addressAutocomplete.addListener('place_changed', function () {
          const place = vm.addressAutocomplete.getPlace();
          if (!place.geometry) {
            console.log('No details available or autocomplete skipped for input: \'' + place.name + '\'');
            return;
          }

          // Fill in the address from the autocomplete object
          vm.fillInAddress(place);
        });
      } catch (error) {
        console.log('autocomplete error')
        console.log(error);
        vm.handleAutocompleteError();
      }

      // Fallback to ensure the form is not disabled if the autocomplete fails
      // Fallback to ensure input is always editable
      setInterval(() => {
        const input = document.getElementById('location-input');
        if (input && (input.hasAttribute('readonly') || input.disabled)) {
          vm.handleAutocompleteError();
        }
      }, 1000);
    },
    // Google js library locks up the input if it errors, so we are very careful to make sure it doesn't happen
    handleAutocompleteError() {
      const element = document.getElementById('location-input');
      if (!element) {
        return;
      }

      element.removeAttribute('disabled');
      element.removeAttribute('readonly');
      element.style.cssText = '';
      element.placeholder = '';
    },
    // Autocomplete handling from Google Places API
    fillInAddress(place) {
      const SHORT_NAME_ADDRESS_COMPONENT_TYPES =
        new Set(['street_number', 'administrative_area_level_1', 'postal_code', 'country']);

      const ADDRESS_COMPONENT_TYPES_IN_FORM = [
        'location',
        'locality',
        'administrative_area_level_1',
        'postal_code',
        'country',
      ];

      function getComponentName(componentType) {
        for (const component of place.address_components || []) {
          if (component.types[0] === componentType) {
            return SHORT_NAME_ADDRESS_COMPONENT_TYPES.has(componentType) ?
              component.short_name :
              component.long_name;
          }
        }
        return '';
      }

      function getComponentText(componentType) {
        return (componentType === 'location') ?
          `${getComponentName('street_number')} ${getComponentName('route')}` :
          getComponentName(componentType);
      }

      for (const componentType of ADDRESS_COMPONENT_TYPES_IN_FORM) {
        // Step 1: Identify the Vue data property
        const dataProperty = this.idDataMap[`${componentType}-input`];
        this[dataProperty] = getComponentText(componentType);
      }
    },
    clearBillingInfo() {
      this.nameOnCard = '';
      this.addressLine1 = '';
      this.addressLine2 = '';
      this.city = '';
      this.state = '';
      this.zip = '';
      this.country = 'US';
    },
    // In some situations, we remotely retrieve this (like embedding)
    fetchBillingAcctInfo() {
      // If we already have this info, just init everything
      if (this.billingPartnerId != null && this.billingPartnerKey != null) {
        if (this.useStripe) {
          if (this.billingPartnerId === 'wiq_platform') {
            // Just use our platform billing system
            this.stripe = Stripe(this.billingPartnerKey)
          } else {
            // Use Stripe Connect
            this.stripe = Stripe(this.billingPartnerKey, {
              stripeAccount: this.billingPartnerId,
            });
          }
        }
      } else {
        // If we can't bootstrap ourselves, attempt to remotely fetch details to bootstrap
        const vm = this;
        const url = `/api/team/v1/account/${vm.billingPartner}/${vm.apiKey}`;
        axios.get(url)
          .then((response) => {
            vm.billingPartnerId = response.data.billing_partner.acct_id;
            vm.billingPartnerKey = response.data.billing_partner.platform_key;
            if (vm.useStripe) {
              vm.stripe = Stripe(vm.billingPartnerKey, {
                stripeAccount: vm.billingPartnerId,
              });
            }
          })
          .catch((error) => {
            console.error('Unable to get Stripe info for team');
            console.error(error);
          });
      }
    },
    // primary color, text color, and useInter are all optional params
    setupPaymentElement(intentSecret, primaryColor, textColor, useInter) {
      if (this.useStripe) {
        const vm = this;
        vm.intentSecret = intentSecret;

        let colorPrimary = primaryColor || '#27AA83'
        let colorText = textColor || '#324D67'
        // We don't use the variable version in this case as I'm not sure it's working
        let fontFamily = useInter ? "Inter, Open Sans, system-ui, sans-serif" : "Open Sans, system-ui, sans-serif";
        let cssSrc = useInter ? "https://fonts.googleapis.com/css?family=Inter" : "https://fonts.googleapis.com/css?family=Open+Sans"
        const options = {
          clientSecret: vm.intentSecret,
          fonts: [{
            cssSrc: cssSrc
          }],
          appearance: {
            theme: "stripe",
            variables: {
              colorPrimary: colorPrimary,
              colorText: colorText,
              colorDanger: "#BB2525",
              fontFamily: fontFamily,
              fontWeightNormal: "600"
            },
          },
        };
        vm.elements = vm.stripe.elements(options);

        // Mount the form
        const paymentElement = vm.elements.create("payment", {
          business: {
            name: vm.teamName,
          },
          terms: {
            card: "never",
          },
        });
        paymentElement.mount("#payment-element");
      }
    },
    // What callers should call
    // We expect a tokenized method to exist on the component that the mix is included with.
    getPaymentToken(billableName, billableEmail, billableZip) {
      if (this.useStripe) {
        this.getStripeToken();
      } else if (this.useJustifi) {
        this.getJustifiToken(billableName, billableEmail, billableZip);
      }
    },
    // Private for Stripe
    async getStripeToken() {
      const vm = this;

      if (vm.creatingToken) {
        return;
      }

      vm.clearError();
      vm.creatingToken = true;
      const elements = vm.elements;

      const { error } = await vm.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        vm.errorMessage = error.message;
        vm.error = true;
        vm.creatingToken = false;
      } else {
        // Usually the promise resolves straight to the return_url, but because of our redirect_if flag,
        // we handle success inline here.
        // Now we need to retrieve the intent to pass it into the endpoint so we know which payment method to charge
        // For some reason this api call is not working with async/await so doing old school calls
        vm.stripe.retrieveSetupIntent(vm.intentSecret).then(function (result) {
          vm.creatingToken = false;
          if (result.error) {
            vm.showError(result.error.message);
          } else {
            vm.tokenized(result.setupIntent.payment_method);
          }
        });
      }
    },
    // Private for Justifi
    getJustifiToken(billableName, billableEmail, billableZip) {
      this.error = false;
      this.creatingToken = true;

      const justiForm = document.querySelector("justifi-card-form");
      justiForm.validate()
        .then((data) => {
          console.log('justifi-card-form validated. Is valid? ', data.isValid);
          if (!data.isValid) {
            this.errorMessage = 'Please correct the errors shown above.';
            this.error = true;
            this.creatingToken = false;
            return;
          }

          this.error = false;
          // All of this information would come from your form instead of being hard coded
          // Card number, expiration and cvv are collected on our iframe
          // TODO poke more billing info into here, now that we collect it?
          const paymentMethodData = {
            name: billableName,
            email: billableEmail,
            address_postal_code: billableZip,
            metadata: {} // optional
          };
          justiForm.tokenize(this.billingPartnerKey, paymentMethodData, this.billingPartnerId)
            .then((response) => {
              this.creatingToken = false;
              // This is where you can submit the form and use the payment method token
              // on your backend
              console.log('justifi-card-form tokenized: ', response);
              if (response.error) {
                this.errorMessage = `Error ${response.error.code}: ${response.error.message}`;
                this.error = true;
                return;
              }
              const data = response.data;
              this.tokenized(data.card.token);
            })
            .catch((error) => {
              // But this never throws?
              console.log('error caught: ', error);
              this.errorMessage = `Unknown Error ${error}`;
              this.error = true;
            });
        });
    }
  }
}
