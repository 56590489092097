<template>
    <div>
        <div>
            <p v-if="error" class="text-danger">
                {{ errorMessage }}
            </p>
        </div>
        <div>
          <div v-if="infoMissing" class="alert alert-danger">
            <h3 class="text-semi m-t-none">
              Missing Required Info
            </h3>
            <p>
              The following wrestlers are marked present, but need updated info before they can be paired.
            </p>
            <ul>
              <li v-for="wp in wrestlersMissingInfo">
                {{ wp.first_name }} {{ wp.last_name }}
              </li>
            </ul>
          </div>
          <div v-if="!infoMissing && !finalized">
            <ladda-button @lbClicked="finalizeRoster"
                          el-class="btn-primary btn-lg btn-block text-heavy m-t-m"
                          :loading="finalizingRoster">
              Finalize Roster For Round {{ teamScore.current_scramble_round }}
            </ladda-button>
            <div class="text-center">
              <small>Once finalized, you can't change your roster for this round</small>
            </div>
            <div class="alert alert-info m-t-sm">
              <div class="flex justify-space-between align-items-center">
                <h3 class="text-semi m-t-none m-b-none">
                  {{ presentCount }} wrestlers checked-in
                </h3>
                <button class="btn btn-default" @click="showOnlyCheckedIn = !showOnlyCheckedIn">
                  {{ showOnlyCheckedIn ? 'Show All' : 'Show Checked-In Only' }}
                </button>
              </div>
              <div v-if="showOnlyCheckedIn" class="m-t-xs">
                <i class="fa fa-info-circle"></i>
                Not seeing all {{ presentCount }} checked-in wrestlers below? Try removing your roster filter
              </div>
            </div>
          </div>
          <div v-if="finalized" class="alert alert-info m-t-m">
            <strong>Check-ins are finalized for round {{ teamScore.current_scramble_round }} with {{ presentCount }} wrestlers. No further edits can be made.</strong>
          </div>
        </div>
        <spinner v-if="loading"></spinner>
        <div v-if="!loading && !finalized">
          <div class="form-group m-t-m">
              <label>Search wrestlers</label>
              <div class="" style="position: relative">
                <input
                    v-model="search"
                    type="text"
                    id="wrestler_search"
                    placeholder="Find by name or weight..."
                    class="form-control"
                    maxlength="25"
                />
                <i
                    @click="search = ''"
                    v-if="search && search.length > 0"
                    class="cursor-pointer fa fa-times color-gray-400"
                    style="
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        transform: translate(-66%, 29%);
                      "
                ></i>
              </div>
            </div>
          <match-check-in-form v-for="wrestler in searchedProfiles"
                               :key="wrestler.id"
                               :wrestler-profile="wrestler"
                               :check-in="checkInFor(wrestler)"
                               :event-id="event.id"
                               :editable="!finalized"
          ></match-check-in-form>
        </div>
        <div>
            <div v-if="infoMissing" class="alert alert-danger">
                <h3 class="text-semi m-t-none">
                    Missing Required Info
                </h3>
                <p>
                    The following wrestlers are marked present, but need updated info before they can be paired.
                </p>
                <ul>
                    <li v-for="wp in wrestlersMissingInfo">
                        {{ wp.first_name }} {{ wp.last_name }}
                    </li>
                </ul>
            </div>
            <div v-if="!infoMissing && !finalized">
                <div class="alert alert-info m-t-xl">
                    <h3 class="text-semi m-t-none m-b-none">
                        {{ presentCount }} wrestlers checked-in
                    </h3>
                </div>
                <ladda-button @lbClicked="finalizeRoster"
                              el-class="btn-primary btn-lg btn-block text-heavy m-t-md"
                              :loading="finalizingRoster">
                    Finalize Roster For Round {{ teamScore.current_scramble_round }}
                </ladda-button>
                <div class="text-center">
                    <small>Once finalized, you can't change your roster for this round</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from './spinner.vue';
import MatchCheckInForm from './match_check_in_form.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'match-check-in-list',
  components: { LaddaButton, MatchCheckInForm, Spinner },
  props: {
    event: {
      type: Object,
      required: true
    },
    initialTeamScore: {
      type: Object, 
      required: true
    }
  },
  computed: {
    searchedProfiles() {
      let filtered = this.wrestlers;

      if (this.showOnlyCheckedIn) {
        filtered = filtered.filter((wp) => {
          const checkIn = this.checkInFor(wp);
          return checkIn && checkIn.status === 'present';
        });
      }

      if (!this.search || this.search === "") {
        return filtered;
      }

      if (isNaN(this.search)) {
        return filtered.filter((wp) =>
            wp.full_name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return filtered.filter((wp) =>
            wp.weight_class.includes(this.search)
        );
      }
    },
    presentCount() {
      const checkInCount = _.countBy(this.checkIns, (check_in) => check_in.status === 'present');

      return checkInCount.true || 0;
    },
    infoMissing() {
      return this.wrestlersMissingInfo.length > 0;
    },
    wrestlersMissingInfo() {
      return _.filter(this.checkedInWrestlers, (wp) => {
        const age = _.get(wp, 'dob', null);
        const experience = _.get(wp, 'experience', null);
        const weight = _.get(wp, 'weight_class', null);
        return (!age || !experience || !weight);
      });
    },
    checkedInWrestlers() {
      const vm = this;
      return _.filter(vm.wrestlers, (wp) => {
        const checkIn = vm.checkInFor(wp);
        if (!checkIn) {
          return false;
        }

        return checkIn.status === 'present';
      });
    },
  },
  data() {
    return {
      loading: false,
      wrestlers: [],
      teamScore: null,
      checkIns: [],
      error: false,
      errorMessage: '',
      finalized: false,
      search: '',
      showOnlyCheckedIn: false,
      finalizingRoster: false,
      finalizeError: false,
      finalizeErrorMessage: 'Error finalizing roster',
      rosters: [],
      wrestlersUrl: null,
      hasInitializedWrestlers: false,
      sort: 'last_name',
    };
  },
  created() {
    const vm = this;
    vm.teamScore = vm.initialTeamScore;
    vm.loadAllWrestlers();
    if (vm.teamScore && vm.teamScore.roster_status === 'final') {
      vm.finalized = true;
    }
    vm.$notificationManager.$on('scramble-wrestler-updated', vm.updateWrestler);
    vm.$notificationManager.$on('wrestler-checked-in', vm.updateCheckIn);
    vm.$notificationManager.$on('roster-filter-applied', vm.changeRoster);
    vm.$notificationManager.$on('sort-filter-applied', vm.changeSort);
    vm.$notificationManager.$on('pairing-complete', vm.reloadTeamScore);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('scramble-wrestler-updated', vm.updateWrestler);
    vm.$notificationManager.$off('wrestler-checked-in', vm.updateCheckIn);
    vm.$notificationManager.$off('roster-filter-applied', vm.changeRoster);
    vm.$notificationManager.$off('sort-filter-applied', vm.changeSort);
    vm.$notificationManager.$off('pairing-complete', vm.reloadTeamScore);
  },
  methods: {
    reloadTeamScore() {
      const vm = this;
      const url = vm.$apiService.eventTeamScoreUrl(vm.event.id, vm.teamScore.id);
      axios.get(url)
        .then((response) => {
          vm.teamScore = response.data;
          console.log(vm.teamScore);
          console.log('reloaded team score');
          if (vm.teamScore.roster_status === 'final') {
            vm.finalized = true;
          } else {
            vm.finalized = false;
          }
        });
    },
    updateWrestler(wrestlerId, wrestlerProfile) {
      let vm = this;
      let indexToReplace = null;

      for (let i = 0; i < vm.wrestlers.length; i++) {
        let wp = vm.wrestlers[i];
        if (wp.id === wrestlerId) {
          indexToReplace = i;
          break;
        }
      }

      vm.$set(vm.wrestlers, indexToReplace, wrestlerProfile);
    },
    loadAllWrestlers: _.throttle(function () {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.loading = true;
      vm.error = false;

      let url = vm.wrestlersUrl || vm.$apiService.filteredWrestlers(false, [], this.sort, 100, 'union');
      const requestUrl = url;

      vm.$apiService.loadAllPages(url, 'wrestlers')
        .then((objects) => {
          if (requestUrl === vm.wrestlersUrl) {
            vm.wrestlers = objects;
            vm.loadAllCheckIns();
          } else {
            vm.loading = false;
          }
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving roster ${error.toString()}`;
          vm.error = true;
        });
    }, 500),
    loadAllCheckIns() {
      const vm = this;
      const url = vm.$apiService.checkInsUrl(vm.event.id);

      vm.$apiService.loadAllPages(url, 'check_ins')
        .then((objects) => {
          vm.checkIns = objects;
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving attendance check-ins ${error.toString()}`;
          vm.error = true;
        });
    },
    checkInFor(wrestler) {
      const vm = this;
      const wrestlerId = wrestler.id;

      return _.find(vm.checkIns, (check) => check.wrestler_profile_id === wrestlerId);
    },
    buildLocalCheckIn(id, status, wrestler) {
      return {
        id,
        status,
        wrestler_profile_id: wrestler.id,
        event_id: this.event.id,
        notes: '',
      };
    },
    updateCheckIn(checkInId, status, wrestlerProfile, notes) {
      const vm = this;
      let checkIn = vm.checkInFor(wrestlerProfile);

      if (!checkIn) {
        checkIn = vm.buildLocalCheckIn(checkInId, status, wrestlerProfile);
      }

      checkIn.status = status;
      checkIn.notes = notes;

      const index = _.findIndex(vm.checkIns, { id: checkInId });
      if (index !== -1) {
        vm.checkIns.splice(index, 1, checkIn);
      } else {
        vm.checkIns.push(checkIn);
      }
    },
    finalizeRoster: _.throttle(function () {
      const vm = this;
      if (vm.finalizingRoster) {
        return;
      }

      if (!confirm('Are you sure you want to finalize the roster? This action cannot be undone.')) {
        return;
      }

      vm.finalizingRoster = true;
      const url = vm.$apiService.teamScoreUrl(vm.teamScore.id);
      const params = {
        team_score: {
          roster_status: 'final',
        },
      };
      axios.put(url, params)
        .then((response) => {
          vm.finalizingRoster = false;
          vm.finalized = true;
          vm.$notificationManager.$emit('roster-finalized');
        })
        .catch((error) => {
          vm.finalizingRoster = false;
          vm.finalizeError = true;
        });
    }, 500),
    changeRoster(rosters, strategy) {
      const vm = this;
      vm.rosters = rosters;
      vm.wrestlers = [];
      
      if (rosters.length > 0) {
        vm.hasInitializedWrestlers = true;
      }
      
      if (rosters.length === 0 && !vm.hasInitializedWrestlers) {
        //vm.wrestlersUrl = null;
        //vm.loading = false;
        //return;
      }
      
      vm.checkForFilterChange();
    },
    changeSort(sort) {
      const vm = this;
      vm.sort = sort;
      
      if (!vm.hasInitializedWrestlers) {
        return;
      }
      
      vm.checkForFilterChange();
    },
    checkForFilterChange() {
      const rosterIds = _.map(this.rosters, 'id');
      let newUrl = this.$apiService.filteredWrestlers(true, rosterIds, this.sort, 100, 'union');
      
      if (newUrl !== this.wrestlersUrl) {
        this.wrestlersUrl = newUrl;
        this.loadAllWrestlers();
      }
    },
  },
};
</script>
