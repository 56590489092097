// This mixin is used to communicate with the native app. 
// It is used to show the save button, notify the app of validation errors, and pop to the root view.
// The root Vue instance is the one that handles the communication with the native app, this simply provides the interface.
// It is also used to handle the native app ready event.

export const appEmbedMix = {
  props: {
    forAppEmbed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // Delegate all public interface methods to root Vue instance
    setupNativeSaveButton(saveCallback) {
      if (!this.forAppEmbed) {
        return;
      }
      this.$root.setupNativeSaveButton(saveCallback);
    },
    notifyAppOfValidationError(errorMessage) {
      if (!this.forAppEmbed) {
        return;
      }
      this.$root.notifyAppOfValidationError(errorMessage);
    },
    askAppToPop(notification) {
      if (!this.forAppEmbed) {
        return;
      }
      this.$root.askAppToPop(notification);
    },
    askAppToPush(url, title) {
      if (!this.forAppEmbed) {
        return;
      }
      this.$root.askAppToPush(url, title);
    }
  }
};
