<template>
    <div>
        <div class="grid sm-grid-cols-1 md-grid-cols-2 gap-sm" :class="colsForPrimaryPill">
            <div v-for="predicate in primaryPillPredicates"
                 :class="primaryPillClass(predicate)"
                 @click="changePrimaryFilter(predicate)"
            >
                {{ predicate.label }}
            </div>
        </div>
        <div class="flex justify-space-between flex-col-xs-only gap-y-sm m-t-sm m-t-only-xs-lg">
            <div class="flex flex-wrap gap-x-sm align-items-baseline">
              <div v-if="secondaryFilters.length > 0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" class="h-6 w-6 text-gray-400"><path d="M463.952 0H48.057C5.419 0-16.094 51.731 14.116 81.941L176 243.882V416c0 15.108 7.113 29.335 19.2 40l64 47.066c31.273 21.855 76.8 1.538 76.8-38.4V243.882L497.893 81.941C528.042 51.792 506.675 0 463.952 0zM288 224v240l-64-48V224L48 48h416L288 224z"/></svg>
              </div>
              <div class="flex flex-wrap gap-sm">
                  <div v-for="filter in notEnabledSecondaryFilters">
                      <div class="w-fit rounded-xs cursor-pointer p-xxs"
                            :class="secondaryFilterClass(filter)"
                            @click.prevent="toggleSecondaryFilter(filter)">
                          {{ filter.label }}
                      </div>
                  </div>
              </div>
            </div>
            <div class="flex flex-wrap gap-x-sm align-items-baseline">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor" class="h-6 w-6 text-gray-400"><path d="M272 288H48.1c-42.6 0-64.2 51.7-33.9 81.9l111.9 112c18.7 18.7 49.1 18.7 67.9 0l112-112c30-30.1 8.7-81.9-34-81.9zM160 448L48 336h224L160 448zM48 224h223.9c42.6 0 64.2-51.7 33.9-81.9l-111.9-112c-18.7-18.7-49.1-18.7-67.9 0l-112 112C-16 172.2 5.3 224 48 224zM160 64l112 112H48L160 64z"/></svg>
              </div>
              <div class="flex gap-x-sm flex-col-xs-only gap-y-sm">
                <select v-model="currentSort" id="filtered-list-sort" class="form-control" name="sort">
                  <option v-for="sort in sorts" :value="sort.value">{{ sort.label }}</option>
                </select>
              </div>
              <button v-if="showExport" class="btn btn-default" @click="exportToCSV" :disabled="exporting">Export</button>
            </div>
        </div>
        <div class="m-t-xs">
          <div class="flex flex-wrap gap-sm">
            <div v-for="filter in activeSecondaryFilters">
              <div class="w-fit rounded-xs cursor-pointer p-xxs"
                      :class="secondaryFilterClass(filter)"
                      @click.prevent="toggleSecondaryFilter(filter)">
                  {{ filter.label }}
              </div>
              <div v-if="isEnabled(filter)" class="m-t-xxs border-solid border-2 rounded-xs border-blue-600 p-sm">
                    <p class="font-semi text-blue-600">{{ filter.predicateLabel }}</p>
                    <select v-model="filter.activePredicate" class="form-control">
                        <option selected disabled></option>
                        <option v-for="predicate in filter.predicates" :value="predicate">{{
                                predicate.label
                            }}
                        </option>
                    </select>
                    <div v-if="filter.activePredicate" class="m-t-sm">
                        <div v-if="filter.activePredicate.type === 'DateBetween'">
                            <date-pair-picker @dateRangeChanged="dateRangeChanged" 
                                :initial-start="getInitialStartMoment(filter)"
                                :initial-end="getInitialEndMoment(filter)">
                            </date-pair-picker>
                        </div>
                        <div v-if="filter.activePredicate.type === 'TextContains'">
                            <input type="text" :placeholder="filter.activePredicate.placeholder"
                                    class="form-control"
                                    v-on:input="textContainsChanged($event.target.value, filter.activePredicate)"
                            >
                        </div>
                        <div v-if="filter.activePredicate.type === 'MultiSelect'">
                            <label class="checkbox-inline" v-for="(name, id) in filter.activePredicate.options">
                                <input type="checkbox" :id="`roster-${id}`" :value="id" v-on:change="multiSelectChanged($event, filter.activePredicate)"> {{ name }}
                            </label>
                        </div>
                        <div v-if="filter.activePredicate.type === 'SingleSelect'">
                          <select class="form-control" @change="singleSelectChanged($event, filter.activePredicate)">
                            <option selected disabled></option>
                            <option v-for="option in filter.activePredicate.options" :value="option.value">
                              {{ option.label }}
                            </option>
                          </select>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>      
        <p v-show="error"><span class="text-danger">{{ errorMessage }}</span></p>
        <spinner v-if="loading"></spinner>
        <table class="w-full table table-collapse m-t-md" v-if="ui === 'table'">
            <thead v-if="headers.length > 0">
            <tr class="bg-gray-50 border border-gray-100 border-y border-solid">
                <th scope="col" class="upper-label font-semi" v-for="(header, index) in headers">
                    {{ header }}
                </th>
            </tr>
            </thead>
            <!-- We expect the slot to define tbody -->
            <slot name="row"
                  v-for="(row, index) in results"
                  :data="row"
                  :index="index"
            >
            </slot>
        </table>
        <div v-if="ui === 'grid'" :class="gridClasses">
          <slot name="row"
                v-for="(row, index) in results"
                :data="row"
                :index="index"
          >
          </slot>
        </div>
        <div v-if="noResults">
          <slot name="noresults"></slot>
        </div>
        <div class="flex justify-space-between m-t-sm">
            <p>
                Viewing <span class="font-semi">{{ viewableRange }}</span> of <span class="font-semi"> {{
                totalCount
                }}</span>
            </p>
            <div class="flex gap-x-sm">
                <button @click.prevent="previousPage" class="btn btn-default" :disabled="this.page <= 1">Previous
                </button>
                <button @click.prevent="nextPage" class="btn btn-default" :disabled="this.page >= this.totalPages">
                    Next
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import qs from 'qs';
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';
import { downloadMix } from './mixins/download_mix';
import { formattingMixin } from './mixins/formatting_mix';
import DatePairPicker from './date_pair_picker.vue';
import moment from 'moment';

export default {
  name: 'filered-list',
  mixins: [errorableMix, downloadMix, formattingMixin],
  components: {
    DatePairPicker,
    Spinner
  },
  props: {
    // Table header row
    headers: {
      type: Array,
      required: true,
    },
    // The predicates available for filtering
    filters: {
      type: Array,
      required: true,
    },
    /*
      The sort options available
     */
    sorts: {
      type: Array,
      default() {
        return [{
          value: 'id desc',
          label: 'ID desc'
        }];
      },
    },
    responseKey: {
      type: String,
      required: true
    },
    objectType: {
      type: String,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    expand: {
      type: Array,
      default() {
        return [];
      },
    },
    // The function that transforms each row to a row for CSV export
    exportRowFormat: {
      type: Function,
      required: false,
    },
    exportHeaders: {
      type: Array,
      required: false,
    },
    // This should actually be a Composable with Vue, so the calling components can do all the UI stuff.
    ui: {
      type: String,
      default: 'table'
    },
    gridClasses: {
      type: String,
      default: 'grid grid-cols-3 sm-grid-cols-1 md-grid-cols-1 gap-sm m-t-md',
    },
    nestedTabPane: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      currentSort: this.sorts[0].value,
      activeSecondaryFilters: [],
      appliedPredicates: [],
      results: [],
      loading: false,
      page: 1,
      perPage: 30,
      totalCount: 0,
      cancelSource: null,
      exporting: false,

      // For pagination
      start: null,
      end: null,
    };
  },
  created() {
    this.appliedPredicates = _.concat(this.defaultPrimaryPredicates, this.forcedPredicates);
    // Normally this is hung off of the function definition itself;
    // however having two components on the same page appears to screw it up.
    // hooking it up inside the create appears to isolate it to the unique component
    this.textContainsChanged = _.debounce(this.textContainsChanged, 500);
    this.$notificationManager.$on('open-feed', this.openFeed);
    this.$notificationManager.$on('chart-range-changed', this.chartRangeChanged);
  },
  destroyed() {
    this.$notificationManager.$off('open-feed', this.openFeed);
    this.$notificationManager.$off('chart-range-changed', this.chartRangeChanged);
  },
  computed: {
    noResults() {
      return !this.loading && this.totalCount === 0;
    },
    showExport() {
      return this.exportHeaders && this.exportRowFormat;
    },
    colsForPrimaryPill() {
        return `grid-cols-${this.primaryPillPredicates.length}`
    },
    defaultPrimaryPredicates() {
      return _.filter(this.primaryPillPredicates, { default: true });
    },
    forcedPredicates() {
      return _.flatMap(_.filter(this.filters, { 'type': 'always_applied' }), 'predicates');
    },
    primaryPillPredicates() {
      let primaryFilters = _.filter(this.filters, { 'type': 'primary' });
      if (primaryFilters.length > 0) {
        return _.head(primaryFilters).predicates;
      }

      return [];
    },
    secondaryFilters() {
      return _.filter(this.filters, { 'type': 'secondary' });
    },
    notEnabledSecondaryFilters() {
      return _.filter(this.secondaryFilters, (filter) => {
        return !this.isEnabled(filter);
      });
    },
    ransackParams() {
      // Ransack params
      let q = {
        s: this.currentSort,
        g: [],
      };
      // Build up the predicate params, using groupings for 'OR' queries within complicated predicates
      this.appliedPredicates.forEach((ap) => {
        if (ap.custom_scope_symbol) {
          q[ap.custom_scope_symbol] = ap.values[ap.custom_scope_symbol];
        } else if (ap.values) {
          let operator = ap.operator || 'and';
          let predGroup = { m: operator };
          for (const [key, value] of Object.entries(ap.values)) {
            predGroup[key] = value;
          }
          q.g.push(predGroup);
        }
      });

      // Throw back the params under the q parameter
      return { q: q };
    },
    customParams() {
      // Any custom params we are punching through. We often do associations this way
      let customParams = {};
      // Build up the predicate params, using groupings for 'OR' queries within complicated predicates
      this.appliedPredicates.forEach((ap) => {
        if (ap.custom_params) {
          for (const [key, value] of Object.entries(ap.custom_params)) {
            customParams[key] = value;
          }
        }
      });

      // Throw back the params under the q parameter
      return customParams;
    },
    url() {
      // if the bse url has a ? in it, the query string is already started and we should use & in betwee it and the qs.stringify
      const paramSeparater = this.baseUrl.includes('?') ? '&' : '?';

      return `${this.baseUrl}${paramSeparater}${qs.stringify(this.ransackParams, { arrayFormat: 'brackets' })}&${qs.stringify({ expand: this.expand }, {arrayFormat: 'brackets'})}&page=${this.page}&per_page=${this.perPage}&${qs.stringify(this.customParams)}`;
    },
    viewableRange() {
      let end = Math.min(this.page * this.perPage, this.totalCount);
      let start = (this.page * this.perPage) - (this.perPage - 1);
      if (end === 0) {
        start = 0;
      }
      return `${start}-${end}`;
    },
    totalPages() {
      if (this.totalCount < this.perPage) {
        return 1;
      }

      return Math.ceil(this.totalCount / this.perPage);
    }
  },
  watch: {
    url() {
      this.getList();
    },
  },
  methods: {
    chartRangeChanged(newRange, interval) {
      console.log("chart range changed", newRange, interval);
      // We reset the predicates to the default ones when the chart range changes to avoid confusion with explore buttons
      this.activeSecondaryFilters = [];
      this.appliedPredicates = _.concat(this.defaultPrimaryPredicates, this.forcedPredicates);
    },
    openFeed(event) {
      if (event.objectType === this.objectType) {
        let predicatesToApply = [];

        let primaryPredKey = event.primaryPredicateKey;
        let primaryToApply = _.find(this.primaryPillPredicates, (pred) => {
          const keyExists = pred.values[primaryPredKey] !== undefined;
          if (keyExists) {
            return pred.values[primaryPredKey] === event.primaryPredicateValue;
          }
          return false;
        });
        if (primaryToApply) {
          predicatesToApply.push(primaryToApply);
        }

        if (event.createdAtStart && event.createdAtEnd) {
          var secondaryToApply = _.find(this.secondaryFilters, (filter) => {
            return filter.attribute === 'created_at';
          });
          if (secondaryToApply) {
            secondaryToApply.activePredicate = secondaryToApply.predicates[0];
            secondaryToApply.activePredicate.values = {
              created_at_gteq: event.createdAtStart.toISOString(),
              created_at_lteq: event.createdAtEnd.endOf('day').toISOString()
            };
            predicatesToApply.push(secondaryToApply.activePredicate);
            this.activeSecondaryFilters = [secondaryToApply];
          }
        }


        console.log("predicatesToApply", predicatesToApply);
        this.appliedPredicates = _.concat(predicatesToApply, this.forcedPredicates);
        // If this component is in a tab pane, activate that tab
        if (this.nestedTabPane) {
          $(`a[href="#${this.nestedTabPane}"]`).tab('show');
          // Scroll to the tab pane to make it visible
          document.querySelector(`#${this.nestedTabPane}`).scrollIntoView({behavior: 'smooth'});
        }
      }
    },
    isEnabled(filter) {
      return _.findIndex(this.activeSecondaryFilters, filter) !== -1;
    },
    dateRangeChanged(start, end) {
      if (start && end) {
        console.log("date range changed", start, end);
        // Apply this predicate
        let predicates = _.map(this.activeSecondaryFilters, 'activePredicate');
        predicates.forEach((pred) => {
          if (pred.type === 'DateBetween') {
            Object.keys(pred.values).forEach((predKey) => {
              if (predKey.includes('gteq')) {
                pred.values[predKey] = start.toISOString();
              } else if (predKey.includes('lteq')) {
                pred.values[predKey] = end.endOf('day').toISOString();
              }
            })
            this.applyPredicate(pred);
          }
        });
      }
    },
    textContainsChanged(text, pred) {
      this.removePredicates([pred]);
      if (text?.length === 0) {
        return;
      }

      // If we have a new predicate value, apply it
      if (text && text.length > 0) {
        for (const [key, value] of Object.entries(pred.values)) {
          pred.values[key] = text;
        }
      }

      this.applyPredicate(pred);
    },
    multiSelectChanged(event, pred) {
        this.removePredicates([pred]);
        console.log("multi select changed", event, pred);
        let rosterId = event.target.value;
        // true for checked, false we remove
        let add = event.currentTarget.checked
        for (const [key, value] of Object.entries(pred.values)) {
            let rosters = pred.values[key];
            if (add) {
                rosters.push(rosterId);
            } else {
                const indexToRemove = rosters.indexOf(rosterId);
                if (indexToRemove > -1) { // only splice array when item is found
                    rosters.splice(indexToRemove, 1); // 2nd parameter means remove one item only
                }
            }
            pred.values[key] = rosters;
        }
        this.applyPredicate(pred);
    },
    singleSelectChanged(event, pred) {
      this.removePredicates([pred]);
      let newSelection = event.target.value;
      // reset the values and apply the new one
      pred.values = {};
      pred.values[pred.predicate_key] = newSelection;
      this.applyPredicate(pred);
    },
    changePrimaryFilter(predicate) {
      this.removePredicates(this.primaryPillPredicates);
      this.applyPredicate(predicate);
    },
    applyPredicate(predicate) {
      // For DateBetween predicates, check if the date range already exists
      if (predicate.type === 'DateBetween') {
        const existingDatePredicate = this.appliedPredicates.find(p => 
          p.type === 'DateBetween' && 
          p.values?.created_at_gteq === predicate.values?.created_at_gteq &&
          p.values?.created_at_lteq === predicate.values?.created_at_lteq
        );
        if (!existingDatePredicate) {
          console.log("adding date predicate", predicate);
          this.appliedPredicates.push(predicate);
        } else {
          console.log("date predicate already exists", predicate);
        }
      } else {
        console.log("adding non-date predicate", predicate);
        this.appliedPredicates.push(predicate); 
      }
    },
    removePredicates(predicates) {
      this.appliedPredicates = _.filter(this.appliedPredicates, function (p) {
        return _.indexOf(predicates, p) === -1;
      });
    },
    toggleSecondaryFilter(filter) {
      let index = _.findIndex(this.activeSecondaryFilters, filter);
      if (index !== -1) {
        // Remove it and all associated predicates
        this.activeSecondaryFilters.splice(index, 1);
        this.removePredicates(filter.predicates);
      } else {
        // Turn this one on, but do not active any actual predicates
        this.activeSecondaryFilters.push(filter);
        if (filter.predicates?.length === 1) {
            // Pre-select the predicate if there is only 1 predicate option
            filter.activePredicate = filter.predicates[0];
        }
      }
    },
    nextPage() {
      this.page = this.page + 1;
    },
    previousPage() {
      this.page = this.page - 1;
    },
    primaryPillClass(predicate) {
      let foundIndex = _.findIndex(this.appliedPredicates, predicate);
      if (foundIndex === -1) {
        // Default gray pill
        return 'border border-solid rounded-xs border-gray-400 text-gray-400 p-sm cursor-pointer';
      }

      // Selected pill
      return 'border border-solid border-1 rounded-xs bg-blue-50 text-blue-600 font-heavy p-sm cursor-pointer';
    },
    secondaryFilterClass(filter) {
      let filterEnabled = this.isEnabled(filter);
      return {
        'border-gray-400': !filterEnabled,
        'text-gray-500': !filterEnabled,
        'border-dashed': !filterEnabled,
        'border': !filterEnabled,
        'border-solid': filterEnabled,
        'border-blue-600': filterEnabled,
        'text-blue-600': filterEnabled,
        'font-heavy': filterEnabled,
        'border-2': filterEnabled,
      };
    },
    getList() {
      const vm = this;
      vm.loading = true;

      // Cancel inflight requests
      if (vm.cancelSource) {
        vm.cancelSource.cancel();
      }
      vm.cancelSource = axios.CancelToken.source();
      axios.get(this.url, { cancelToken: vm.cancelSource.token })
        .then((response) => {
          vm.cancelSource = null;
          vm.results = response.data[vm.responseKey];
          vm.totalCount = parseInt(response.headers.totalcount);
          vm.loading = false;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('request cancelled');
          } else {
            vm.errorMessage = `Error retrieving ${vm.responseKey}`;
            vm.error = true;
          }
        });
    },
    exportToCSV() {
      if (this.exporting) {
        return;
      }

      let vm = this;
      vm.exporting = true;
      // Pull all data, with a larger per_page for exporting
      const urlToPull = `${vm.baseUrl}?${qs.stringify(vm.ransackParams, { arrayFormat: 'brackets' })}&page=1&per_page=90`;
      vm.$apiService.loadAllPages(urlToPull, vm.responseKey)
        .then((objects) => {
          const fileName = `WrestlingIQ Export - ${vm.formatWithMoment(Date(), 'M/D/YYYY, hh:mm a')}.csv`;
          const data = _.map(objects, function (r) {
            return vm.exportRowFormat(r, vm);
          });
          const rows = _.concat([vm.exportHeaders], data);
          vm.downloadCSVFile(fileName, rows);
          vm.exporting = false;
        })
        .catch((error) => {
          vm.errorMessage = `Error exporting ${error.toString()}`;
          vm.error = true;
          vm.exporting = false;
        });

    },
    getInitialStartMoment(filter) {
      if (!filter.activePredicate || !filter.activePredicate.values) return null;
      const isoString = filter.activePredicate.values.created_at_gteq;
      return isoString ? moment(isoString) : null;
    },
    getInitialEndMoment(filter) {
      if (!filter.activePredicate || !filter.activePredicate.values) return null;
      const isoString = filter.activePredicate.values.created_at_lteq;
      return isoString ? moment(isoString) : null;
    },
  }
};
</script>
