<template>
  <div class="grid grid-cols-1 gap-y-3 justify-space-between align-items-start border border-solid border-gray-200 rounded-sm p-b-m">
    <div class="grid grid-cols-1 text-center gap-x-sm align-items-start">
      <div class="flex p-sm flex-col align-items-center justify-center bg-gray-50 text-gray-700 text-18 font-heavy tracking-tight rounded-t-sm">
        <div class="upper-label">{{ eventDayOfWeek }}</div>
        <div class="text-18">{{ eventMonthDay }}</div>
        <div class="text-14 m-t-xxs">{{ eventTime }}</div>
      </div>
      <div class="p-x-m">
        <h2 class="m-t-sm m-b-none">
          <component :is="forAppEmbed ? 'div' : 'a'" 
                    class="flex gap-xxs align-items-center justify-center flex-wrap flex-col"
                    :href="!forAppEmbed ? `/events/${event.id}` : undefined"
                    @click="handleEventClick"
                    target="_blank">
            <span>{{ event.name }}</span>
            <div>
              <button class="btn btn-default btn-compact m-t-xxs">Event Details <i class="fa fa-info-circle"></i></button>
            </div>
          </component>
        </h2>
      </div>
    </div>
    <div v-if="event.location" class="flex gap-x-xs align-items-baseline m-t-sm p-x-m">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
        <path fill-rule="evenodd" d="m7.539 14.841.003.003.002.002a.755.755 0 0 0 .912 0l.002-.002.003-.003.012-.009a5.57 5.57 0 0 0 .19-.153 15.588 15.588 0 0 0 2.046-2.082c1.101-1.362 2.291-3.342 2.291-5.597A5 5 0 0 0 3 7c0 2.255 1.19 4.235 2.292 5.597a15.591 15.591 0 0 0 2.046 2.082 8.916 8.916 0 0 0 .189.153l.012.01ZM8 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" clip-rule="evenodd" />
      </svg>
      <div style="word-break: break-word;" v-html="urlify(event.location, true)"></div>
    </div>
    <div class="m-t-sm">
      <div v-for="invite in invites" class="flex justify-space-between flex-nowrap align-items-center m-t-sm p-x-m">
        <p class="m-t-none m-b-xxs">
          {{ invite.invitee.first_name }} {{ invite.invitee.last_name }} <spinner :inline="true" v-if="loading"></spinner>
        </p>
        <div class="check-in-buttons flex-shrink-0">
          <button class="btn btn-primary" :class="{ 'btn-outline': invite.status !== 'accepted'}" @click="updateStatus(invite, 'accepted')"><i class="fa fa-check"></i> Yes</button>
          <button class="btn btn-danger" :class="{ 'btn-outline': invite.status !== 'declined'}" @click="updateStatus(invite, 'declined')"><i class="fa fa-times"></i> No</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import EventTime from './event_time.vue';
import { errorableMix } from './mixins/errorable_mix';
import { urlHelperMixin } from './mixins/url_helper_mix';
import { appEmbedMix } from './mixins/app_embed_mix';

export default {
  components: { Spinner, EventTime },
  name: "EventWithEventInvites",
  mixins: [errorableMix, urlHelperMixin, appEmbedMix],
  props: {
    // This expects an event object with expanded event_invite json array
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      invites: [],
    }
  },
  created() {
    this.invites = _.clone(this.event.event_invites);
  },
  computed: {
    eventStart() {
      if (this.event.all_day) {
        return moment(this.event.start_at.split('T')[0]);
      }

      return moment(this.event.start_at);
    },
    eventEnd() {
      if (this.event.all_day) {
        const ambiguousEnd = this.event.end_at.split('T')[0];
        // Subtract one day for 'human speak' UI display
        return moment(ambiguousEnd).subtract(1, 'day');
      }

      return moment(this.event.end_at);
    },
    multiDayEvent() {
      return this.eventEnd.diff(this.eventStart, 'days') >= 1;
    },
    eventMonthDay() {
      const eventMonthStart = this.eventStart.format('MMM');
      const eventMonthEnd = this.eventEnd.format('MMM');
      if (eventMonthEnd !== eventMonthStart) {
        if (this.eventStartYear !== this.eventEndYear) {
          return `${this.eventStart.format('MMM D')}, ${this.eventStartYear} - ${this.eventEnd.format('MMM D')}, ${this.eventEndYear}`
        }
        // If we are here the year is the same
        return `${this.eventStart.format('MMM D')} - ${this.eventEnd.format('MMM D')}, ${this.eventStartYear}`
      }

      if (this.multiDayEvent) {
        return `${this.eventStart.format('MMM D')} - ${this.eventEnd.format('D')}, ${this.eventStartYear}`;
      }

      return `${this.eventStart.format('MMM D')}, ${this.eventStartYear}`;
    },
    eventStartYear() {
      return this.eventStart.format('YYYY');
    },
    eventEndYear() {
      return this.eventEnd.format('YYYY');
    },
    eventDayOfWeek() {
      if (this.multiDayEvent) {
        return `${this.eventStart.format('ddd')}-${this.eventEnd.format('ddd')}`;
      }

      return this.eventStart.format('ddd');
    },
    eventTime() {
      if (this.event.all_day) {
        return "All day"
      }

      if (this.multiDayEvent) {
        return `${this.eventStart.format('M/D hh:mm a')} to ${this.eventEnd.format('M/D hh:mm a')}`;
      }

      return `${this.eventStart.format('hh:mm a')} to ${this.eventEnd.format('hh:mm a')}`;
    }
  },
  methods: {
    updateStatus(invite, status) {
      let vm = this;
      if (vm.loading) {
        return;
      }
      vm.loading = true;
      const url = vm.$apiService.eventInviteUrl(invite.id);
      axios.put(url, {event_invite: { status: status } })
          .then((response) => {
            invite.status = status;
            vm.loading = false;
            vm.$notificationManager.$emit('event-invite-updated');
            vm.$notificationManager.$emit('show-toast', 'Invite update', true);
          })
          .catch((error) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', 'Invite update failed', false);
          });
    },
    handleEventClick(e) {
      if (this.forAppEmbed) {
        e.preventDefault();
        this.askAppToPush(`${window.location.origin}/app_embeds/events/${this.event.id}`, 'View Event');
      }
    }
  },
};
</script>
