<template>
  <div>
    <div class="grid grid-cols-2 sm-grid-cols-1 md-grid-cols-1 gap-x-lg gap-y-lg">
      <div class="ibox">
        <div class="ibox-content">
          <div role="form" class="p-x-m">
          <h1 class="m-t-md m-b-md font-heavy">Subscribe to Basic Website Services</h1>
          <div class="form">
            <div class="form-group">
              <label class="text-18">Payment Method</label>
              <div v-if="billingProfile">
                <div v-if="hasCardOnFile">
                  <div class="flex align-items-center gap-x-sm m-t-sm">
                    <div v-html="cardLogo"></div>
                    <div class="font-heavy">* * * * {{ billingProfile.default_payment_method_last4 }}</div>
                    <div>
                      {{ billingProfile.default_payment_method_exp_month }} / {{ billingProfile.default_payment_method_exp_year }}
                    </div>
                    <div @click.prevent="toggleCardModal" class="text-12 text-gray-600 hover-text-gray-500 cursor-pointer">
                      <i class="fa fa-pencil"></i> Change card
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p class="m-y-none">
                    <i class="fa fa-exclamation-triangle text-red-600"></i> No card on file.
                  </p>
                  <button @click.prevent="toggleCardModal" class="btn btn-default flex gap-x-xxxs m-t-xxs align-items-baseline">
                    <i class="fa fa-plus m-r-xs"></i> <span>Add Card</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group m-t-lg">
              <ladda-button @lbClicked="subscribe" el-class="btn-primary btn-block text-semi p-y-sm" :loading="loading" :disabled="!hasCardOnFile">
                <i class="fa fa-credit-card"></i> &nbsp; Subscribe
              </ladda-button>
              <div class="text-center m-t-xxs">
                <small v-if="hasCardOnFile">Card ending in {{ billingProfile.default_payment_method_last4 }} will be charged.</small>
                <small v-else class="font-semi text-red-700"><i class="fa fa-exclamation-triangle"></i> Add a card to continue.</small>
              </div>
              <div v-if="error" class="text-center m-t-xxs">
                <small>
                  <span class="text-danger">{{ errorMessage }}</span>
                </small>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-800 rounded-sm p-x-lg p-y-m">
        <div class="flex flex-col">
          <p class="upper-label m-t-m bg-green-600 text-green-50 w-fit rounded-full p-x-sm p-y-xs">Review Purchase</p>
          <div class="flex flex-col-xs-only justify-space-between gap-y-xs">
            <h1 class="font-heavy text-gray-50 m-t-xs">Basic Website Services</h1>
            <h2 class="text-gray-50 font-heavy">$99/year USD</h2>
          </div>
          <div id="add-on-description" class="text-gray-100">
            <p>
              You are purchasing the Basic Website Services add-on for {{ teamName }}. Your card will be charged $99/year USD until cancelled.
            </p>
            <p>
              We offer a <span class="font-heavy bg-green-600 text-green-50 p-x-xxs">30 day money back guarantee</span>. If you are not satisfied email support@wrestlingiq.com for a full refund.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="card-modal" class="modal fade hidden-print" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Update card on file</h4>
          </div>
          <div class="modal-body" v-if="billingProfile && renderCardForm" style="margin-bottom: -8px;">
            <update-cc
                :billing-profile="billingProfile"
                billing-partner="stripe"
                :billing-key="billingProfile.billing_update_meta.billing_partner_key"
                :billing-acct-id="billingProfile.billing_update_meta.billing_partner_id"
                :billing-intent-secret="billingProfile.billing_update_meta.intent.client_secret"
                :team-name="billingProfile.billing_update_meta.team.name"
                :team-currency="teamCurrency"
                :should-redirect="false"
                @card-updated="cardUpdated"
                :use-google-autocomplete="true"
            >
            </update-cc>
            <div class="m-t-sm">
              <button type="button" class="btn btn-default btn-block" @click.prevent="toggleCardModal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from 'v-money';
import Spinner from './spinner.vue';
import { cardLogoMix } from '../../shared/card_logo_mix';
import UpdateCc from './update_cc.vue';
import { errorableMix } from './mixins/errorable_mix';
import LaddaButton from '../../shared/ladda_button.vue';
import { formattingMixin } from './mixins/formatting_mix';

export default {
  name: 'add-on-subscribe',
  mixins: [cardLogoMix, errorableMix, formattingMixin],
  components: {
    UpdateCc,
    LaddaButton,
    Money
  },
  props: {
    teamName: {
      type: String,
      required: true
    },
    teamCurrency: {
      type: String,
      default: 'usd',
    }
  },
  data() {
    return {
      loading: false,
      billingProfile: null,
      renderCardForm: false,
      error: false,
      errorMessage: ''
    };
  },
  computed: {
    hasCardOnFile() {
      return this.billingProfile && this.billingProfile.default_payment_method_last4;
    },
    cardLogo() {
      switch (this.billingProfile.default_payment_method_brand) {
        case 'visa':
          return this.visaLogo;
        case 'mastercard':
          return this.mastercardLogo;
        case 'amex':
        case 'american_express':
          return this.amexLogo;
        case 'discover':
          return this.discoverLogo;
        default:
          return `<div>${this.billingProfile.default_payment_method_brand}</div>`;
      }
    },
  },
  mounted() {
    this.loadBillingProfile();
  },
  methods: {
    loadBillingProfile() {
      const vm = this;
      const url = vm.$apiService.teamBillingProfileUrl(true);
      axios.get(url)
        .then((response) => {
          vm.billingProfile = response.data;
        })
        .catch((error) => {
          console.error(error);
          vm.errorMessage = 'Something went wrong loading the team billing profile. Try again or email support@wrestlingiq.com';
          vm.error = true;
        });
    },
    toggleCardModal() {
      this.renderCardForm = !this.renderCardForm;
      $(this.$el).find('#card-modal').modal('toggle');
    },
    cardUpdated() {
      this.toggleCardModal();
      this.loadBillingProfile();
    },
    subscribe() {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.error = false;
      vm.loading = true;

      const url = vm.$apiService.addOnSubscribeUrl();
      axios.post(url, { add_on: 'basic_website_services' })
        .then(() => {
          vm.loading = false;
          vm.$notificationManager.$emit('show-toast', 'Successfully subscribed to website services!', true);
          window.location.assign('/add_ons/website');
        })
        .catch((error) => {
          vm.errorMessage = vm.parseErrorResponse(error);
          vm.error = true;
          vm.loading = false;
        });
    }
  }
};
</script>
